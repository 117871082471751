import PadraoModel from 'src/core/model/PadraoModel.js'

export default class CredereClientModel extends PadraoModel {
  recurso = 'credere/client';
  microServico = 'usuario';
  include = null;
  searchFixo = null;
  nome = 'Credere Client';

  colunasGrid = []

  form = {
    id: null,
    customer: {
      name: null,
      nickname: null,
      cpf: null,
      born_at: null,
      place_of_birth: null,
      state_of_birth_id: null,
      genre_id: null,
      education_id: null,
      property: null,
      public_person: false,
      mother: null,
      father: null,
      document_type: null,
      rg: null,
      rg_date: null,
      rg_state_id: null,
      rg_issuing: null,
      has_cnh: false,
      cnh: null,
      cnh_type_id: null,
      marital_status_id: null,
      nationality: null,
      have_bank_account: false,
      bank_references: [
        {
          bank_id: null,
          overdraft: false,
          agency: null,
          open_at: null,
          account_number: null,
          digit: null
        }
      ],
      job_reference: {
        joined_at: null,
        income_cents: 0,
        another_income_cents: null,
        another_income_type_id: null,
        details: null,
        first_job: false,
        professional_ocupation_id: null,
        profession_id: null,
        ocupation_id: null,
        department: null,
        name: null,
        cnpj: null,
        company_activity_id: null,
        address: {
          city: null,
          number: null,
          street: null,
          zip_code: '',
          manual: false,
          complement: null,
          state_id: null,
          neighborhood: null
        },
        phone: {
          code: null,
          number: null,
          phone_type_id: null
        },
        previous_work: null,
        previous_work_start_at: null,
        previous_work_end_at: null,
        previous_job_phone: {
          code: null,
          number: null,
          phone_type_id: null
        }
      },
      accountant: null,
      phones: [
        {
          code: null,
          number: null,
          phone_type_id: 1
        },
        {
          code: null,
          number: null,
          phone_type_id: 2
        }
      ],
      emails: [
        {
          address: null
        }
      ],
      address: {
        address_type_id: null,
        city: null,
        number: null,
        street: null,
        zip_code: '',
        manual: false,
        complement: null,
        state_id: null,
        neighborhood: null,
        set_time_year: 0,
        set_time_month: null,
        build_type_id: null,
        rent_value_cents: null
      },
      addresses: [
        {
          address_type_id: 2,
          city: null,
          number: null,
          street: null,
          zip_code: '',
          manual: false,
          complement: null,
          state_id: null,
          neighborhood: null,
          set_time_year: null,
          set_time_month: null,
          build_type_id: null,
          rent_value_cents: null
        }
      ],
      personal_references: [
        {
          name: null,
          relationship: null,
          city: null,
          phone: {
            code: null,
            number: null,
            phone_type_id: null
          }
        }
      ],
      have_credit_card: false,
      credit_cards: null,
      has_made_funding: false,
      previous_funding_bank_id: null,
      accept_boleto: false,
      note: null
    }
  }

  constructor (logado) {
    super()
    this.formLimpo = Object.assign({}, this.form)
    if (logado) {
      this.recurso += `/usuario/${logado.id}`
    }
  }
}
