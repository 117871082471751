/* eslint-disable no-unused-vars */
import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import Bancos from 'src/core/mixin/Bancos'
import VeiculosEntradaModel from 'src/model/veiculo/VeiculosEntradaModel'
import CredereClientModel from 'src/model/usuario/CredereClientModel'
import CredereSimulacaoModel from 'src/model/usuario/CredereSimulacaoModel'
import CrederePropostaModel from 'src/model/usuario/CrederePropostaModel'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import LogradouroCepModel from 'src/model/cep/LogradouroCepModel.js'
import ConsultaPlacaIcarrosModel from 'src/model/veiculo/ConsultaPlacaIcarrosModel'
import VeiculoCalcularPrecoModel from 'src/model/veiculo/VeiculoCalcularPrecoModel'
import CalcularValorParcelaModel from 'src/model/veiculo/CalcularValorParcelaModel'
import vTopFilters from 'src/core/filters/vTopFilters'
import QRadioCustom from 'src/components/RadioCustom/QRadioCustom.vue'
import Modal from 'src/components/Modal/Modal.vue'
import { LocalStorage, clone } from 'quasar'

import MarcaModel from 'src/model/veiculo/MarcaModel'
import ModeloModel from 'src/model/veiculo/ModeloModel'
import VersaoModel from 'src/model/veiculo/VersaoModel'
import CambioModel from 'src/model/veiculo/CambioModel'
import CombustivelModel from 'src/model/veiculo/CombustivelModel'
import DominioCredereModel from 'src/model/veiculo/DominioCredereModel'
import DominioNovosCredereModel from 'src/model/veiculo/DominioNovosCredereModel'
import VeiculoResultado from 'src/components/VeiculoResultado.vue'
/* eslint-disable */
const situacaoContinueSuaProposta = 1
const situacaoEmAnalise = 2
const situacaoCreditoRecusado = 3
const situacaoCreditoAprovado = 4
const situacaoCreditoAprovadoComAlteracao = 5
/* eslint-enable */

export default {
  name: 'Proposta',
  components: { QRadioCustom, Modal, VeiculoResultado },
  mixins: [NotificacaoMixin, EstadosBrasileiros, vTopFilters, Bancos],
  props: ['dados'],
  data () {
    return {
      modelCalcularValorParcela: new CalcularValorParcelaModel(),
      carregandoStatusProposta: false,
      valorMinimoFinanciamento: 3000,
      mostrarStatus: false,
      placaLocalizada: false,
      forceRender: 0,
      progress: 0.1,
      buffer: 1,
      veiculosDisponiveisEntrada: [],
      simulacaoId: null,
      simulacaoIdCredere: null,
      tempo: 20,
      nacionalidades: [
        {
          gentilico: 'afegãne',
          nome_pais: 'Afeganistão',
          nome_pais_int: 'Afghanistan',
          sigla: 'AF'
        },
        {
          gentilico: 'sul-africana',
          nome_pais: 'África do Sul',
          nome_pais_int: 'South Africa',
          sigla: 'ZA'
        }
      ],
      dataAgendamento: '',
      horarioAgendamento: '',
      etapas: {},
      moneyFormatForDirective: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },

      // Modal
      modalTipo: '',
      value: false,
      /* */

      diretivaKm: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },

      parcelas: [
        { numero: 24, valor: '1.500,00' },
        { numero: 32, valor: '1.500,00' },
        { numero: 48, valor: '1.500,00' },
        { numero: 60, valor: '1.500,00' }
      ],

      logado: LocalStorage.getItem('logado'),
      usuarioSelecionado: LocalStorage.getItem('logado'),
      veiculoEntradaSelecionado: LocalStorage.getItem('veiculoEntradaSelecionado'),
      modelCalcularPreco: new VeiculoCalcularPrecoModel(),
      calculandoPreco: false,

      // Credere
      modelVeiculosEntrada: new VeiculosEntradaModel(),
      modelCredereClient: new CredereClientModel(),
      modelCredereSimulacao: new CredereSimulacaoModel(),
      modelCredereSimulacaoBusca: new CredereSimulacaoModel(),
      modelCredereProposta: new CrederePropostaModel(),
      modelCrederePropostaId: null,

      possuiCarroEntrada: false,
      veiculoQuitado: false,
      veiculoSaldoDevedor: 0,
      passo1: false,
      passo2: false,
      passo3: false,
      passo4: false,
      salvandoPasso1: false,
      salvandoPasso2: false,
      salvandoPasso3: false,
      salvandoPasso4: false,

      // Listagens
      listagens: [
        'marca_id',
        'modelo_id',
        'versao_id',
        'cambio_id',
        'combustivel_id'
      ],
      listas: [],
      listaVersao: [],
      versaoAnoMaximo: 2099,
      versaoAnoMinimo: 2010,
      limparVeiculoEntrada: true,
      models: [],
      carregamentos: [],
      filtrosListagens: [],
      /* */

      formPasso2: {
        valorExtra: 0
      },

      formPasso4: null,

      resultSimulacao: [],
      resultadosCalcularPreco: null,
      encontrouSimulacao: false,
      encontrouSimulacaoPreAprovada: false,

      sexoOptions: [
        {
          label: 'Masculino',
          value: 'M'
        },
        {
          label: 'Feminino',
          value: 'F'
        }
      ],

      termosPasso3: false,

      statesOptions: [],
      genreOptions: [],
      educationOptions: [],
      documentTypeOptions: [],
      cnhTypeOptions: [],
      maritalStatusOptions: [],
      nationalityOptions: [],
      addressTypeOptions: [],
      anotherIncomeTypeOptions: [],
      professionOptions: [],
      occupationOptions: [],
      companyActivityOptions: [],
      dddOptions: [],
      mesesOptions: [],
      phoneTypeOptions: [],
      buildTypeOptions: [],
      relationshipOptions: [],
      combustiveisOptions: [],

      diretivaIncomeCents: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },

      diretivaSemMascara: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 0,
        masked: false
      },

      dadosSimulacao: { situacao_id: 1 }
    }
  },
  computed: {
    dados: {
      get: function () {
        return this.$store.getters['data/getData']
      },
      set: function (value) {
        this.$store.commit('data/putData', value)
        // this.data = Object.assign({}, this.data, value)
      }
    },
    locale () {
      const _meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
      const _dias = [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado'
      ]
      return {
        months: _meses,
        monthsShort: _meses.map(mes => mes.substr(0, 3)),
        days: _dias,
        daysShort: _dias.map(mes => mes.substr(0, 3))
      }
    },
    resultSimulacaoOrder: {
      get () {
        function sortParcela (a, b) {
          return a.parcela < b.parcela ? -1 : a.parcela > b.parcela ? 1 : 0
        }
        return this.resultSimulacao.sort(sortParcela)
      }
    },
    veiculo: {
      get: function () {
        return this.$store.getters['depois/getData']
      }
    },
    valorVeiculoComp () {
      if (this.pcd) {
        return this.dados.valor_venda_pcd ?? this.dados.valor_venda_pf ?? 0
      }
      if (this.pj) {
        return this.dados.valor_venda_pj ?? this.dados.valor_venda_pf ?? 0
      }
      return this.dados ? (this.dados.valor_venda_pf && this.dados.valor_venda_pf > 0 ? this.dados.valor_venda_pf : 0) : 0
    },
    valorEntradaComp: {
      get: function () {
        return this.possuiCarroEntrada ? (parseFloat(this.formPasso2.valorExtra) + parseFloat(this.valorEntradaVeiculoComp)) : parseFloat(this.formPasso2.valorExtra)
      }
    },
    valorEntradaVeiculoComp: {
      get: function () {
        return this.possuiCarroEntrada
          ? (this.veiculoQuitado ? parseFloat(this.modelVeiculosEntrada.form.valor_mercado)
            : (parseFloat(this.veiculoSaldoDevedor) >= parseFloat(this.modelVeiculosEntrada.form.valor_mercado)
              ? 0 : parseFloat(this.modelVeiculosEntrada.form.valor_mercado) - parseFloat(this.veiculoSaldoDevedor)))
          : 0
      }
    },
    stateName: {
      get () {
        const encontrado = this.statesOptions.find(e => e.id === this.modelCredereClient.form.customer.address.state_id)
        return encontrado ? encontrado.name : this.modelCredereClient.form.customer.address.state_id
      }
    },
    stateJobName: {
      get () {
        const encontrado = this.statesOptions.find(e => e.id === this.modelCredereClient.form.customer.job_reference.address.state_id)
        return encontrado ? encontrado.name : this.modelCredereClient.form.customer.job_reference.address.state_id
      }
    }
  },
  watch: {
    possuiCarroEntrada (agora, antes) {
      if (agora !== antes) {
        this.resultSimulacao = []
        this.passo4 = false
      }
      let logado = LocalStorage.getItem('logado')
      console.log('entrada', this.modelVeiculosEntrada.form, logado)
      if (!this.modelVeiculosEntrada.form.usuario_id && logado && logado.id) {
        let send = clone(this.modelVeiculosEntrada.form)
        send.versao_id = this.modelVeiculosEntrada.form.versao_id
        send.modelo_id = this.modelVeiculosEntrada.form.modelo_id
        send.usuario_id = logado.id
        console.log('entrada.enviar', send)
        this.modelVeiculosEntrada.salvar(send).then((o) => console.log(o)).catch((e) => console(e))
      }
    },
    passo2 (value) {
      if (value && this.salvandoPasso1 !== 'ok') {
        this.notificacao('aviso', 'Finalize a etapa 1')
        this.passo2 = false
      }
    },
    passo3 (value) {
      if (value && this.salvandoPasso2 !== 'ok') {
        this.notificacao('aviso', 'Finalize a etapa 2')
        this.passo3 = false
      }
    },
    passo4 (value) {
      if (value && this.salvandoPasso3 !== 'ok') {
        this.notificacao('aviso', 'Finalize a etapa 3')
        this.passo4 = false
      }

      if (value && this.dadosSimulacao && this.dadosSimulacao.situacao_id >= 2) {
        // this.passo4 = false
      }
    },
    'modelCredereProposta.form.veiculos_entradas_id' (agora) {
      if (agora) {
        let obj = this.veiculosDisponiveisEntrada.find((item) => {
          if (item && item.id === agora) return item
        })
        if (obj) {
          this.modelVeiculosEntrada.form = obj
          this.modelCalcularPreco.form.placa_uf = obj.uf_placa ? obj.uf_placa : 'SP'
          this.veiculoSaldoDevedor = obj.valor_saldo_devedor
          LocalStorage.set('veiculoEntradaSelecionado', this.modelVeiculosEntrada.form)
        }
      }
    },
    'modelVeiculosEntrada.form.placa' (agora) {
      if (agora && agora.length < 8) {
        this.limparCamposVeiculoEntrada()
      }
    },
    'modelVeiculosEntrada.form.ano_fabricacao' (agora) {
      if (agora && agora.length === 4) {
        if (this.$refs && this.$refs.ano_modelo) {
          this.$refs.ano_modelo.validate()
        }
      }
    },
    'modelVeiculosEntrada.form.marca_id' (agora, anterior) {
      if (agora !== anterior && (anterior !== null || agora === null) && this.limparVeiculoEntrada) {
        this.$refs.formCrederePasso1.resetValidation()
        this.listas['versao_id'] = []
        if (LocalStorage.has('veiculo-listas')) {
          let listas = LocalStorage.getItem('veiculo-listas')
          listas['versao_id'] = []
          LocalStorage.set(`veiculo-listas`, listas)
          this.keys['versao_id']++
        }
        let data = { modelo_id: null, versao_id: null, combustivel_id: null, cambio_id: null, ano_fabricacao: null, ano_modelo: null, valor_mercado: 0, valor_minimo: 0, valor_maximo: 0 }
        // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, data)
        this.limparCamposVeiculoEntrada(data)
      }
      this.buscarListagemRequest('modelo_id', false, `marca_id:${agora}`)
    },
    'modelVeiculosEntrada.form.modelo_id' (agora, anterior) {
      if (agora !== anterior && (anterior !== null || agora === null) && this.limparVeiculoEntrada) {
        let data = { versao_id: null, combustivel_id: null, cambio_id: null, ano_fabricacao: null, ano_modelo: null, valor_mercado: 0, valor_minimo: 0, valor_maximo: 0 }
        // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, data)
        this.limparCamposVeiculoEntrada(data)
      }
      this.buscarListagemRequest('versao_id', false, `modelo_id:${agora}`)
    },
    'modelVeiculosEntrada.form.versao_id' (agora, anterior) {
      if (agora !== anterior && (anterior !== null || agora === null) && this.limparVeiculoEntrada) {
        // let data = { combustivel_id: null, cambio_id: null, ano_fabricacao: null, ano_modelo: null, valor_mercado: 0, valor_minimo: 0, valor_maximo: 0 }
        let data = { combustivel_id: null, cambio_id: null, valor_mercado: 0, valor_minimo: 0, valor_maximo: 0 }
        // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, data)
        this.limparCamposVeiculoEntrada(data)
      }
      this.validarAnoVeiculo()
    },
    'form.etapas.3.tipoCadastro' (agora, antes) {
      if (agora !== antes) {
        this.forceRender++
      }
    },
    'formPasso2.valorExtra' (value) {
      const valorVeiculo = this.dados ? this.valorVeiculoComp : 0
      // const entrada = this.modelVeiculosEntrada.form.valor_mercado + value
      // const entrada = value
      const entrada = value + (this.possuiCarroEntrada ? this.modelVeiculosEntrada.form.valor_mercado : 0)
      this.resultSimulacao = []
      this.passo4 = false
      if (entrada > valorVeiculo && valorVeiculo > 0 && value > 0) {
        setTimeout(() => {
          this.formPasso2.valorExtra = parseInt(valorVeiculo - this.modelVeiculosEntrada.form.valor_mercado)
          this.forcerRender++
        }, 1000)
        this.notificacao('aviso', 'A entrada é superior que valor do veículo anunciado!', 8000)
      }
    },
    'modelCredereClient.form.customer.job_reference.professional_ocupation_id' (value) {
      // 4 = Aposentado
      if (value === 4) {
        this.modelCredereClient.form.customer.job_reference.profession_id = 22
      }
    },
    'modelVeiculosEntrada.form.valor_mercado' (value) {
      this.resultSiqmulacao = []
      this.passo4 = false
    },
    'veiculoSaldoDevedor' (value) {
      this.resultSimulacao = []
      this.passo4 = false
    },
    'veiculoQuitado' (value) {
      this.resultSimulacao = []
      this.passo4 = false
    }
  },
  async created () {
    this.filtrosListagens = this.listagens.reduce((acc, atual) => {
      let buscasPermitidas = [
        'categoria_id',
        'marca_id',
        'modelo_id',
        'versao_id',
        'cor_id'
      ]
      acc[`${atual}`] = {
        filtrado: [],
        termo: '',
        permitirBusca: buscasPermitidas.includes(atual)
      }

      return acc
    }, [])

    let forms = Array.from(new Array(4)).reduce((acc, _, index) => {
      acc[index + 1] = {}
      return acc
    }, {})
    forms[4] = {
      veiculoEntrada: '',
      valorExtra: '',
      parcelaOpcao: null
    }
    this.etapas = Array.from(new Array(4)).reduce((acc, _, index) => {
      acc[index + 1] = {
        expandido: false,
        salvo: false,
        form: forms[index + 1]
      }
      if (index === 2) {
        acc[index + 1] = { ...acc[index + 1], formPj: forms['pj'] }
      }
      return acc
    }, {})
  },
  mounted () {
    if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
      if (LocalStorage.has('usuarioSelecionado')) {
        this.usuarioSelecionado = LocalStorage.getItem('usuarioSelecionado')
      }
    }
    this.buscarStatusProposta()
    this.listas = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = []
      return acc
    }, {})

    this.carregamentos = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = false

      return acc
    }, {})

    this.keys = this.listagens.reduce((acc, atual, index) => {
      acc[`${atual}`] = index + 1

      return acc
    }, [])

    this.models = {
      marca_id: MarcaModel,
      modelo_id: ModeloModel,
      versao_id: VersaoModel,
      cambio_id: CambioModel,
      combustivel_id: CombustivelModel
    }

    this.buscarTodasAsListagens()
    this.getDominiosCredere()
    this.getLocalStorageCredere()
    if (!this.$route.params.propostaid) {
      this.submitCrederePasso4(true)
    }

    if (this.$route.params.publico === 'pcd') {
      this.pcd = true
    }
    if (this.$route.params.publico === 'pj') {
      this.pj = true
    }
    // this.buscarListagemRequest('marca_id', false)

    // Preecher CPF usuario no client credere
    const telefone = this.$options.filters.soNumeros(this.usuarioSelecionado.celular).toString()
    this.modelCredereClient = new CredereClientModel()
    this.modelCredereClient.form.customer.name = this.usuarioSelecionado.nome
    this.modelCredereClient.form.customer.emails[0].address = this.usuarioSelecionado.email
    this.modelCredereClient.form.customer.cpf = this.usuarioSelecionado.documento
    this.modelCredereClient.form.customer.phones[0].code = telefone.toString().substr(0, 2)
    this.modelCredereClient.form.customer.phones[0].number = telefone.toString().substr(2)
    this.modelCredereClient.form.customer.born_at = this.usuarioSelecionado.data_nascimento
    this.modelCredereClient.form.customer.genre_id = this.usuarioSelecionado.genero ? (this.usuarioSelecionado.genero === 'Masculino' ? 1 : 2) : null

    this.modelCredereClient.form.customer.address.zip_code = this.usuarioSelecionado.cep
    this.modelCredereClient.form.customer.address.street = this.usuarioSelecionado.logradouro
    this.modelCredereClient.form.customer.address.number = this.usuarioSelecionado.numero
    this.modelCredereClient.form.customer.address.complement = this.usuarioSelecionado.complemento
    this.modelCredereClient.form.customer.address.neighborhood = this.usuarioSelecionado.bairro
    this.modelCredereClient.form.customer.address.city = this.usuarioSelecionado.cidade
    /* */
  },
  beforeCreate () {
    let veiculosEntradas = new VeiculosEntradaModel()
    veiculosEntradas.getMeusVeiculos().then(res => {
      this.veiculosDisponiveisEntrada = res.dados.map((item) => {
        let veiculo = item
        veiculo.marca_nome = item.versao.modelo.marca.nome
        veiculo.marca_id = item.versao.modelo.marca.id
        veiculo.label = item.versao.nome + ' - ' + item.placa
        veiculo.modelo_id = item.versao.modelo.id
        veiculo.ano_modelo = item.ano_modelo
        veiculo.versao_id = item.versao.id
        veiculo.combustivel_id = item.combustivel_id
        veiculo.valor_varejo = 0
        return veiculo
      })
      this.veiculosDisponiveisEntrada.unshift({ label: 'Selecione', marca_nome: '', id: null })
      this.modelVeiculosEntrada.form = this.veiculoEntradaSelecionado
      this.modelCalcularPreco.form.placa_uf = this.veiculoEntradaSelecionado.uf_placa ? this.veiculoEntradaSelecionado.uf_placa : 'SP'
      this.modelCalcularPreco.form.km = this.veiculoEntradaSelecionado.km ? this.veiculoEntradaSelecionado.km : 1
      this.modelCredereProposta.form.veiculos_entradas_id = this.veiculoEntradaSelecionado.id
      this.veiculoSaldoDevedor = this.veiculoEntradaSelecionado.valor_saldo_devedor
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    toggleModal (tipo, { valorTarifa } = {}) {
      this.value = !this.value
      this.modalTipo = tipo
    },
    validarAnoVeiculo () {
      if (this.listaVersao && this.listaVersao.length > 0 && this.modelVeiculosEntrada.form.versao_id > 0) {
        // const versaoUsado = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)]
        // if (versaoUsado) {
        //   let versoes = clone(this.listaVersao)
        //   this.versaoAnoMinimo = versoes.filter((obj) => { return versaoUsado.nome === obj.nome }).reduce((a, b) => a < b.ano_inicio ? a : b.ano_inicio, 10000)
        //   versoes = clone(this.listaVersao)
        //   this.versaoAnoMaximo = versoes.filter((obj) => { return versaoUsado.nome === obj.nome }).reduce((a, b) => a > b.ano_final ? a : b.ano_final, 0)
        // }
        this.$refs.ano_modelo.validate()
        this.$refs.ano_fabricacao.validate()
      }
    },
    limparCamposVeiculoEntrada (dados = null) {
      if (dados === null) {
        // this.modelCalcularPreco.form.km = ''
        // this.modelCalcularPreco.form.placa_uf = ''
        this.modelVeiculosEntrada.form.marca_id = null
        this.modelVeiculosEntrada.form.modelo_id = null
        this.modelVeiculosEntrada.form.versao_id = null
        this.modelVeiculosEntrada.form.cambio_id = null
        this.modelVeiculosEntrada.form.combustivel_id = null
        // this.modelVeiculosEntrada.form.ano_fabricacao = null
        // this.modelVeiculosEntrada.form.ano_modelo = null
        this.modelVeiculosEntrada.form.valor_mercado = 0
        this.modelVeiculosEntrada.form.valor_maximo = 0
        this.modelVeiculosEntrada.form.valor_minimo = 0
        this.$refs.formCrederePasso1.resetValidation()
      } else {
        if (!this.modelCredereProposta.form.veiculos_entradas_id) {
          Object.keys(dados).map(res => {
            this.modelVeiculosEntrada.form[`${res}`] = ''
          })
        }
      }
    },
    callbackAcao () {
      if (this.modalTipo === 'financiamento') {
        this.$router.push({ name: 'painel-inicio' })
        this.value = false
      }
    },

    buscarStatusProposta () {
      this.carregandoStatusProposta = true
      const model = new CrederePropostaModel(false, true)
      console.log('this.$route', this.$route)
      if (this.$route.params.propostaid && this.$route.name === 'painel-proposta-editar') {
        console.log(model.include)
        console.log('this.$route.params.propostaid', this.$route.params.propostaid)
        model.getId({ id: this.$route.params.propostaid }).then((res) => {
          if (res.dados) {
            this.responseBuscarStatusProposta(res.dados)
            // setTimeout(() => { this.passo4 = true }, 500)
          } else {
            this.mostrarStatus = false
          }
          this.carregandoStatusProposta = false
        }).catch(error => {
          this.carregandoStatusProposta = false
          this.notificacao('erro', error.msg)
        })
      } else if (this.$route.params.id && this.usuarioSelecionado.id) {
        model.getListagem({
          params: {
            search: `usuario_id:${this.usuarioSelecionado.id};anuncio_id:${this.$route.params.id}`,
            searchJoin: 'and',
            orderBy: 'updated_at',
            sortedBy: 'desc'
          }
        }).then((response) => {
          if (Array.isArray(response.dados) && response.dados.length > 0) {
            this.responseBuscarStatusProposta(response.dados[0])
            // setTimeout(() => { this.passo4 = true }, 500)
          } else {
            this.mostrarStatus = false
          }
          this.carregandoStatusProposta = false
        }).catch(error => {
          this.carregandoStatusProposta = false
          this.notificacao('erro', error.msg)
        })
      }
    },

    responseBuscarStatusProposta (resp) {
      // Preenchendo campos
      if (resp.veiculos_entradas) {
        this.possuiCarroEntrada = true

        if (resp.veiculos_entradas.versao) {
          resp.veiculos_entradas.modelo_id = resp.veiculos_entradas.versao.modelo_id
        }

        if (resp.veiculos_entradas.versao && resp.veiculos_entradas.versao.modelo) {
          resp.veiculos_entradas.marca_id = resp.veiculos_entradas.versao.modelo.marca_id
        }

        if (resp.veiculos_entradas.versao && resp.veiculos_entradas.versao.modelo && resp.veiculos_entradas.versao.modelo.marca) {
          resp.veiculos_entradas.marca_nome = resp.veiculos_entradas.versao.modelo.marca.nome
        }

        if (resp.veiculos_entradas.valor_saldo_devedor) {
          this.veiculoSaldoDevedor = resp.veiculos_entradas.valor_saldo_devedor
        }

        this.modelVeiculosEntrada.form = resp.veiculos_entradas
        this.modelCalcularPreco.form.placa_uf = this.modelVeiculosEntrada.form.uf_placa
        this.modelCalcularPreco.form.km = this.modelVeiculosEntrada.form.km >= 0 ? parseFloat(this.modelVeiculosEntrada.form.km) : 0
        if (resp.veiculos_entradas.valor_mercado) {
          this.modelVeiculosEntrada.form.valor_mercado = resp.veiculos_entradas.valor_mercado
          this.modelVeiculosEntrada.form.valor_maximo = resp.veiculos_entradas.valor_maximo
          this.modelVeiculosEntrada.form.valor_minimo = resp.veiculos_entradas.valor_minimo
        } else {
          this.calcularPreco()
        }
      }

      this.formPasso2.valorExtra = resp.valor_extras ?? 0
      if (resp.response_client_credere) {
        this.modelCredereClient.form = JSON.parse(resp.response_client_credere)
      }

      this.modelCredereSimulacao.form.deal_id = resp.simulacao && resp.simulacao.deal_id ? resp.simulacao.deal_id : null

      this.passo1 = false
      this.salvandoPasso1 = 'ok'
      this.passo2 = false
      this.salvandoPasso2 = 'ok'

      if (resp.situacao_credere_proposta_id >= 2) {
        this.passo3 = false
        this.salvandoPasso3 = 'ok'
        this.passo4 = false
        this.salvandoPasso4 = 'ok'
      }

      if (resp.tipo_negociacao_id === 1) this.pj = true
      if (resp.tipo_negociacao_id === 3) this.pcd = true
      /* */

      if (resp.situacao_credere_proposta_id === 1) {
        this.passo3 = true
        if (this.possuiCarroEntrada) {
          // this.calcularPreco()
        }
      }

      this.modelCredereProposta.form.id = resp.id
      if (this.modelCredereProposta.form.id > 0 && resp.situacao_credere_proposta_id >= 2) {
        this.$emit('atualizarPropostaTitulo')
      }

      /* ######### Resumo proposta ######### */
      const form = resp

      let dadosSimulacao = null
      let dados = {}

      // Dados Simulação credere
      if (form.request_log && form.request_log.response) {
        dadosSimulacao = JSON.parse(form.request_log.response)
        const simulacao = dadosSimulacao.complete_proposal ?? dadosSimulacao.proposal

        if (simulacao) {
          const { proposal_attempt: tentativa } = simulacao
          if (tentativa) {
            let { bank: banco, financed_amount_in_cents: valor, term_financing: parcelas, quota_in_cents: parcela, created_at: data } = tentativa
            valor = (valor / 100)
            valor = resp.valor_financiado_credere ?? valor
            dados = { valor, banco: banco.name, parcelas, valorParcela: (parcela.toFixed(2) / 100), data }
          }
        }
      }
      /* Fim - Dados Simulação credere */

      // Preenchendo os dados
      if (!dados.data) dados.data = form.created_at
      if (!dados.banco && form.banco_id) dados.banco = this.bancoOptions.find(e => parseInt(e.id) === parseInt(form.banco_id)) ? this.bancoOptions.find(e => parseInt(e.id) === parseInt(form.banco_id)).name : null
      if (!dados.parcelas && form.banco_id) dados.parcelas = form.parcela
      if (!dados.valorParcela && form.banco_id) dados.valorParcela = form.valor_parcela
      if (!dados.valor && form.banco_id) dados.valor = form.valor_financiado_credere
      dados.data = this.$options.filters.dateBr(dados.data)
      dados.situacao = form && form.situacao_credere_proposta ? form.situacao_credere_proposta.nome : null
      dados.situacao_id = form && form.situacao_credere_proposta_id ? form.situacao_credere_proposta_id : null
      dados.valorEntrada = form.valor_extras ?? 0

      if (resp.veiculos_entradas_id) {
        let valor = parseFloat(resp.veiculos_entradas.valor_mercado) - parseFloat(resp.veiculos_entradas.valor_saldo_devedor)
        dados.valorEntrada = parseFloat(form.valor_extras) + parseFloat(valor) ?? 0
      }

      dados.valor_financiado_resposta = form.valor_financiado_resposta ?? 0
      dados.valor_total_resposta = form.valor_total_resposta ?? 0
      dados.valor_extras_resposta = form.valor_extras_resposta ?? 0
      dados.valor_parcela_resposta = form.valor_parcela_resposta ?? 0
      dados.parcela_resposta = form.parcela_resposta ?? 0

      // Validar situacoes
      switch (dados.situacao_id) {
        case situacaoEmAnalise:
          dados.color = '0, 155, 223'
          dados.icon = 'app:proposta_analise'
          dados.descricao = 'Estamos aguardando os nossos bancos responderem sobre a sua proposta enviada.'
          break
        case situacaoContinueSuaProposta:
          dados.color = '118, 84, 141'
          dados.icon = 'app:continue-proposta'
          dados.descricao = 'Falta pouco, preencha os dados abaixo e continue a sua proposta para este carro.'
          break
        case situacaoCreditoRecusado:
          dados.color = '204, 123, 22'
          dados.icon = 'close'
          dados.descricao = 'Infelizmente não conseguimos aprovar a proposta enviada.'
          break
        case situacaoCreditoAprovado:
          dados.color = '41, 152, 74'
          dados.icon = 'app:proposta-aprovado'
          dados.descricao = `Recebemos aprovação da sua proposta no banco <b class="text-black">${form.banco_id ? form.banco_id_nome : (dados ? dados.banco : '')}</b>. Agora chegou o momento de finalizamos a compra!`
          break
        case situacaoCreditoAprovadoComAlteracao:
          dados.color = '253, 185, 19'
          dados.icon = 'app:proposta-aprovado-alteracao'
          dados.descricao = `Recebemos aprovação da sua proposta no banco <b class="text-black">${form.banco_id ? form.banco_id_nome : (dados ? dados.banco : '')}</b>, porém com algumas alterações de valores. Se você estiver de acordo, chegou o momento de finalizamos a compra!`
          break
        default:
          dados.color = '118, 84, 141'
          dados.icon = 'app:continue-proposta'
          dados.descricao = 'Falta pouco, preencha os dados abaixo e continue a sua proposta para este carro.'
      }
      /* Fim - Validar situacoes */

      this.dadosSimulacao = Object.assign({}, this.dadosSimulacao, dados)
      /* */

      /* ######### Fim - Resumo proposta ######### */
    },

    getLocalStorageCredere () {
      const passosCredereDetalhes = LocalStorage.getItem('passosCredereDetalhes')
      console.log('vamos12314312', passosCredereDetalhes)
      if (passosCredereDetalhes) {
        this.veiculoQuitado = passosCredereDetalhes.veiculoQuitado
        this.veiculoSaldoDevedor = passosCredereDetalhes.veiculoSaldoDevedor
        this.modelVeiculosEntrada.form = passosCredereDetalhes.passo1
        this.possuiCarroEntrada =
          passosCredereDetalhes.passo1.possuiCarroEntrada

        // this.formPasso2 = passosCredereDetalhes.passo2
        this.modelCredereProposta.form = passosCredereDetalhes.passo4
        this.modelCalcularPreco.form = passosCredereDetalhes.calcularPreco
        // this.modelCredereSimulacao.form = passosCredereDetalhes.simulacaoForm

        this.passo1 = false
        this.salvandoPasso1 = 'ok'
        this.passo2 = false
        this.salvandoPasso2 = 'ok'
        this.passo3 = true

        // if (this.possuiCarroEntrada) {
        //   this.calcularPreco(true)
        // } else {
        //   this.submitCrederePasso4(true)
        // }
        this.formPasso2 = Object.assign({}, this.formPasso2, { valorExtra: passosCredereDetalhes.passo2.valorExtra })
      }

      let dados = LocalStorage.getItem('logado')
      if (dados.observacao_aprovacao) {
        this.modelCredereClient.form = JSON.parse(dados.observacao_aprovacao)
      }

      // setTimeout(() => {
      //   // LocalStorage.remove('passosCredereDetalhes')
      // }, 2000)
    },

    getDominiosCredere () {
      const model = new DominioCredereModel()
      model.getListagem().then(response => {
        const list = response.dados
        if (list) {
          // this.occupationOptions = list['occupation'].sort(this.ordenarLabel)
          this.combustiveisOptions = list['fuel-type'].sort(this.ordenarLabel)
        }
      })

      const modelNovos = new DominioNovosCredereModel()
      modelNovos.getListagem().then(res => {
        const lista =
          res && res.dados && res.dados.domains ? res.dados.domains : null
        if (lista) {
          this.statesOptions = lista['states'].sort(this.ordenarName)
          this.genreOptions = lista['genre'].sort(this.ordenarName)
          this.educationOptions = lista['education'].sort(this.ordenarName)
          this.documentTypeOptions = lista['document_type'].sort(this.ordenarName)
          this.cnhTypeOptions = lista['cnh_type'].sort(this.ordenarName)
          this.maritalStatusOptions = lista['marital_status'].sort(this.ordenarName)
          this.nationalityOptions = lista['nationality'].sort(this.ordenarName)
          this.addressTypeOptions = lista['address_type'].sort(this.ordenarName)
          this.anotherIncomeTypeOptions = lista['another_income_type'].sort(this.ordenarName)
          this.professionOptions = lista['professional_ocupation'].sort(this.ordenarName)
          this.occupationOptions = lista['profession'].sort(this.ordenarName)
          this.companyActivityOptions = lista['company_activity'].sort(this.ordenarName)
          for (let i = 10; i < 100; i++) this.dddOptions.push(i)
          this.phoneTypeOptions = lista['phone_type'].sort(this.ordenarName)
          for (let i = 0; i < 12; i++) { this.mesesOptions.push({ id: i, name: i + 1 }) }
          this.buildTypeOptions = lista['build_type'].sort(this.ordenarName)
          this.relationshipOptions = lista['relationship'].sort(this.ordenarName)

          // Estado
          const estadoMixinEncontrado = this.estados.find(e => e.sigla === this.usuarioSelecionado.estado)
          if (estadoMixinEncontrado) {
            const encontradoState = this.statesOptions.find(ele => this.$options.filters.removerAcentos(ele.name) === this.$options.filters.removerAcentos(estadoMixinEncontrado.nome))
            this.modelCredereClient.form.customer.address.state_id = encontradoState ? encontradoState.id : null
          }
          /* Fim - Estado */
        }
      })
    },

    ordenarLabel (a, b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    },

    ordenarName (a, b) {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    },

    validarCPF (cpf) {
      cpf = this.$options.filters.soNumeros(cpf)
      return cpf && cpf.length >= 11
        ? this.validarCPFCalculo(cpf.toString())
        : null
    },

    validarCPFCalculo (strCPF) {
      var Soma = 0
      var Resto = null
      if (strCPF === '00000000000') return false

      for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i) }
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i) }
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    salvarEtapa (index) {
      let atual = Object.assign({}, this.etapas[index])
      atual.salvo = true
      atual.expandido = false
      this.etapas[index] = Object.assign({}, atual)
    },

    validarCep (campos) {
      const cep = campos.zip_code
      if (cep !== null && cep.length === 9) {
        this.modelLogradouroCep = new LogradouroCepModel()
        this.modelLogradouroCep.recurso = this.modelLogradouroCep.recurso.replace('{cep}', cep)
        this.modelLogradouroCep
          .getListagem()
          .then(res => {
            if (res && res.dados) {
              var objCep = res.dados
              campos.zip_code = objCep.cep
              campos.street = objCep.logradouro
              campos.neighborhood = objCep.bairro
              campos.city = objCep.localidade

              if ((!objCep.logradouro || objCep.logradouro.length === 0) || (!objCep.bairro || objCep.bairro.length === 0) || (!objCep.localidade || objCep.localidade.length === 0)) {
                campos.manual = true
              } else {
                campos.manual = false
              }

              // Estado
              const estadoMixinEncontrado = this.estados.find(e => e.sigla === objCep.uf)
              if (estadoMixinEncontrado) {
                const encontradoState = this.statesOptions.find(ele => this.$options.filters.removerAcentos(ele.name) === this.$options.filters.removerAcentos(estadoMixinEncontrado.nome))
                campos.state_id = encontradoState ? encontradoState.id : null
              }
              /* Estado */
            } else {
              this.notificacao('erro', 'CEP Inválido!')
              this.limparCamposCep(campos)
            }
          })
          .catch(error => {
            this.notificacao('erro', error.msg)
            this.limparCamposCep(campos)
          })
      }
    },

    limparCamposCep (campos) {
      campos.manual = true
      campos.street = null
      campos.neighborhood = null
      campos.number = null
      campos.complement = null
      campos.city = null
      campos.state_id = null
    },

    consultarPlaca (placa = '') {
      // eslint-disable-next-line
      placa = placa ? placa.replace(/[\s-]/g, "").replace("-", "") : null;
      this.placaLocalizada = false
      this.modelCredereProposta.form.veiculos_entradas_id = null
      if (placa && placa.length > 6) {
        const modelPlaca = new ConsultaPlacaIcarrosModel()
        modelPlaca
          .getHashedId(placa)
          .then(res => {
            if (res.erro === 1) {
              this.notificacao(
                'aviso',
                'Veículo não encontrado, preencha as informações do veiculo manualmente'
              )
            } else this.placaResponse(res.dados)
          })
          .catch(() => {
            this.notificacao(
              'aviso',
              'Veículo não encontrado, preencha as informações do veiculo manualmente'
            )
          })
      }
    },

    placaResponse (res) {
      if (res) {
        this.placaLocalizada = true
        this.modalveiculos = false
        this.limparVeiculoEntrada = false

        let camposResponse = clone(res)
        delete camposResponse['situacao_bem']

        let dados = res.dados_consulta
        delete dados.motor

        let camposGenericos = Object.keys(camposResponse).filter(
          campo => campo !== 'dados_consulta'
        )
        camposGenericos.map(campo => {
          this.modelVeiculosEntrada.form[`${campo}_id`] =
            camposResponse[campo].id
        })
        // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, { atualizar: 0 })
        // this.data = Object.assign({}, this.data, value)

        this.modelVeiculosEntrada.form.cambio_id =
          dados['cambioAutomatico'] === 0 ? 2 : 1

        if (
          camposResponse &&
          camposResponse.versao_precificadores &&
          camposResponse.versao_precificadores.length > 0
        ) {
          this.modelCalcularPreco.form.valor_fipe = this.$options.filters
            .soNumeros(camposResponse.versao_precificadores[0].valor)
            .toString()
        }

        if (camposResponse && camposResponse.marca) {
          this.modelVeiculosEntrada.form.marca_nome = camposResponse.marca.nome
        }

        Object.keys(dados).map(campo => {
          switch (campo) {
            case 'marcaId':
              this.modelVeiculosEntrada.form.marca_id = dados[`${campo}`]
              break
            case 'anoFabricacao':
              this.modelVeiculosEntrada.form.ano_fabricacao = dados[`${campo}`]
              break
            case 'anoModelo':
              this.modelVeiculosEntrada.form.ano_modelo = dados[`${campo}`]
              break
            case 'combustivelId':
              this.modelVeiculosEntrada.form.combustivel_id = dados[`${campo}`]
              break
            default:
              break
          }
        })
      }
      setTimeout(() => { this.limparVeiculoEntrada = true }, 3000)
    },
    async submitSimulacaoCredere (passo) {
      this[`salvandoPasso${passo}`] = true
      const aviso = this.notificacao('enviando')

      let combustivel = null
      const send = clone(this.modelCredereSimulacao.form)

      if (this.dados && this.dados.codigo_molicar) {
        combustivel = this.dados.combustivel_objeto ? this.dados.combustivel_objeto.combustivel_nome : ''
        send.vehicle.vehicle_molicar_code = this.dados.codigo_molicar
      } else {
        this.notificacao(
          'aviso',
          'Codigo molicar precisar ser informado no cadastro desse anuncio! Entre em contato com suporte do sistema!',
          10000
        )
        return false
      }

      if (this.usuarioSelecionado) {
        send.usuario_id = this.usuarioSelecionado.id
        if (!send.retrieve_lead) {
          send.retrieve_lead = { cpf_cnpj: this.usuarioSelecionado.documento }
        } else {
          send.retrieve_lead.cpf_cnpj = this.usuarioSelecionado.documento
        }
      }

      if (LocalStorage.has('dealId') && !send.deal_id) {
        send.deal_id = LocalStorage.getItem('dealId')
      }

      if (send.deal_id) send.deal_id = send.deal_id.toString()

      send.seller_cpf = send.retrieve_lead.cpf_cnpj
      send.assets_value = this.dados
        ? parseInt(parseFloat(this.valorVeiculoComp).toFixed(2) * 100).toString()
        : '0'
      // send.vehicle.vehicle_molicar_code = this.dados ? this.dados.codigo_molicar : '01906110-9'
      send.vehicle.licensing_uf = this.dados ? (this.dados.placa_uf ? this.dados.placa_uf : 'SP') : 'SP'
      send.vehicle.manufacture_year = this.dados
        ? this.dados.ano_fabricacao.toString()
        : '0'
      send.vehicle.model_year = this.dados
        ? this.dados.ano_modelo.toString()
        : '0'
      send.vehicle.zero_km = this.dados && this.dados.km === 0 ? '1' : '0'
      send.vehicle.asset_value = this.dados
        ? parseInt(this.valorVeiculoComp).toString()
        : '0'
      send.vehicle.asset_value = this.dados
        ? parseInt(parseFloat(this.valorVeiculoComp).toFixed(2) * 100).toString()
        : '0'
      send.anuncio_id = parseInt(this.$route.params.id)

      const combustivelEncontrado = this.combustiveisOptions.find(e =>
        combustivel.includes(e.label)
      )
      send.vehicle.retrieve_fuel_type = combustivelEncontrado
        ? combustivelEncontrado.credere_identifier
        : '1'

      send.conditions.map((_, index) => {
        send.conditions[index].down_payment = parseInt(this.valorEntradaComp.toFixed(2) * 100).toString() /* Valor Entrada */
        send.conditions[index].financed_amount = (parseInt((this.valorVeiculoComp - this.valorEntradaComp) * 100)).toString() /* Valor total - Valor Entrada */
      })
      send.simulacao = true
      this.modelCredereSimulacao.recurso = 'credere/simulacoes'
      await this.modelCredereSimulacao.salvar(send).then((res) => {
        this.tratarResultado(res, passo)
        aviso()
      }).catch(error => {
        aviso()
        // this[`salvandoPasso${passo}`] = false
        // this.notificacao('erro', error.msg)
        console.log('entrei aqui catch', error)
        this.tratarResultado({ dados: null }, passo)
        this.encontrouSimulacao = true
        // this.notificacao('erro', error.msg)
      })

      if (this.interval) { clearInterval(this.interval) }

      this.interval = setInterval(() => {
        this.tempo--
        if (this.progress >= 1) {
          this.progress = 1
          this.buffer = 1
          this.tempo = 0
          clearInterval(this.interval)
          return
        }
        this.progress = Math.min(1, this.buffer, this.progress + 0.05)
      }, 1000)
      if (!this.encontrouSimulacao) {
        await this.buscarResultadoSimulacao(2, passo)
      }
    },
    async buscarResultadoSimulacao (loop, passo) {
      for (let i = 1; i <= loop; i += 1) {
        if (!this.encontrouSimulacao) {
          await this.timer(8000, passo)
          if (loop === i) {
            this.encontrouSimulacao = true
          }
        }
      }
    },
    async timer (ms, passo) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.simulacaoIdCredere) {
            this.modelCredereSimulacaoBusca.getId({ id: this.simulacaoIdCredere + '/token' }).then(result => {
              result.dados.data.conditions.map(ele => {
                if (ele.process_task && ele.process_task.result && ele.process_task.result.success === true && (ele.pre_approval_status === 2 || ele.pre_approval_status === 3)) {
                  if (!this.encontrouSimulacao) {
                    this.encontrouSimulacaoPreAprovada = true
                    this.tratarResultado(result, passo)
                  }
                  // this.encontrouSimulacao = true
                }
              })
              resolve()
            }).catch(error => {
              reject(error)
            })
          } else {
            resolve()
          }
        }, ms)
      })
    },
    tratarResultado (res, passo) {
      let resquet = res.dados && res.dados.request_log && res.dados.request_log.response ? JSON.parse(res.dados.request_log.response) : null

      if (resquet === null) {
        resquet = res.dados ?? null
      }
      let resultSimulacao = []
      this.passo4 = false

      if (resquet && resquet.data && resquet.data.conditions) {
        if (this.simulacaoIdCredere === null && res.dados && res.dados.credere_simulacao_id) {
          this.simulacaoIdCredere = res.dados.credere_simulacao_id
        }
        if (this.simulacaoId === null && res.dados && res.dados.id) {
          this.simulacaoId = res.dados.id
        }
        this.bancoPrioridade.map(bank => {
          resquet.data.conditions.map(ele => {
            if (ele.bank.febraban_code === bank.id) {
              if (ele.process_task && ele.process_task.result && ele.process_task.result.success === true && (ele.pre_approval_status === 2 || ele.pre_approval_status === 3)) {
                const add = {
                  simulacao_id: this.simulacaoId,
                  condition_id: ele.id ? ele.id : null,
                  parcela: parseInt(ele.installments),
                  status_aprovacao: ele.pre_approval_status,
                  banco: ele.bank && ele.bank.name ? ele.bank.name : null,
                  valorPrimeiraParcela: parseFloat(ele.first_installment_value / 100),
                  valorLiberado: parseFloat(ele.financed_amount / 100)
                }
                resultSimulacao.push(add)
              }
            }
          })
        })
      }

      const parce12 = resultSimulacao.filter(e => e.parcela === 12)
      const parce24 = resultSimulacao.filter(e => e.parcela === 24)
      const parce36 = resultSimulacao.filter(e => e.parcela === 36)
      const parce48 = resultSimulacao.filter(e => e.parcela === 48)
      const parce60 = resultSimulacao.filter(e => e.parcela === 60)

      const newResultSimulacao = []

      if (parce12 && parce12.length > 0) newResultSimulacao.push(parce12.sort(this.sortValorPrimeiraParcela)[0])
      else this.calcularValorParcela(12)

      if (parce24 && parce24.length > 0) newResultSimulacao.push(parce24.sort(this.sortValorPrimeiraParcela)[0])
      else this.calcularValorParcela(24)

      if (parce36 && parce36.length > 0) newResultSimulacao.push(parce36.sort(this.sortValorPrimeiraParcela)[0])
      else this.calcularValorParcela(36)

      if (parce48 && parce48.length > 0) newResultSimulacao.push(parce48.sort(this.sortValorPrimeiraParcela)[0])
      else this.calcularValorParcela(48)

      if (parce60 && parce60.length > 0) newResultSimulacao.push(parce60.sort(this.sortValorPrimeiraParcela)[0])
      else this.calcularValorParcela(60)

      this.resultSimulacao = newResultSimulacao

      this[`salvandoPasso${passo}`] = 'ok'
      this[`passo${passo}`] = false
      this.forcerRender++
      this.passo3 = false
      this.passo4 = true
    },

    sortValorPrimeiraParcela (a, b) {
      return a.valorPrimeiraParcela < b.valorPrimeiraParcela
        ? -1
        : a.valorPrimeiraParcela > b.valorPrimeiraParcela
          ? 1
          : 0
    },

    calcularValorParcela (qtdParcelas) {
      const params = clone(this.modelCalcularValorParcela.form)

      params.ano_modelo = this.dados ? this.dados.ano_modelo.toString() : 0
      params.valor = (parseInt((this.valorVeiculoComp - this.valorEntradaComp) * 100) / 100).toFixed(2)
      params.placa_uf = this.dados ? this.dados.placa_uf ? this.dados.placa_uf : 'SP' : 'SP'
      params.qtd_parcelas = qtdParcelas

      this.modelCalcularValorParcela
        .getListagem({ params })
        .then(res => {
          if (res.dados) {
            const add = {
              simulacao_id: this.simulacaoId,
              condition_id: qtdParcelas,
              parcela: qtdParcelas,
              banco: null,
              valorPrimeiraParcela: res.dados.valor_parcelas,
              valorLiberado: res.dados.valor_parcelas
            }
            if (this.resultSimulacao.findIndex(ind => ind.parcela === qtdParcelas) === -1) {
              this.resultSimulacao.push(add)
            }
          }
        })
        .catch(error => {
          this.notificacao('erro', error.msg)
        })
    },

    parcelaSelecionada (item) {
      this.formPasso4 = item
      if (item.condition_id && item.simulacao_id) {
        this.formPasso4 = item
      } else {
        // this.notificacao('aviso', 'Esta parcela não foi Pré Aprovado!')
      }
    },

    calcularPreco (passosCredereDetalhes = false) {
      this.modelCalcularPreco.form.km = this.modelVeiculosEntrada.form.km ?? 1
      this.modelCalcularPreco.form.placa_uf = this.modelVeiculosEntrada.form.uf_placa ?? 'SP'
      console.log('que??? ', this.modelCalcularPreco.form)
      this.$refs.formCrederePasso1.validate().then(success => {
        if (success) {
          this.calculandoPreco = true
          const aviso = this.notificacao('enviando')

          const send = clone(this.modelCalcularPreco.form)
          send.placa = this.modelVeiculosEntrada.form.placa
          send.marca_id = this.modelVeiculosEntrada.form.marca_id
          send.modelo_id = this.modelVeiculosEntrada.form.modelo_id
          send.ano_modelo = this.modelVeiculosEntrada.form.ano_modelo
          send.versao_id = this.modelVeiculosEntrada.form.versao_id
          send.combustivel_id = this.modelVeiculosEntrada.form.combustivel_id
          console.log('veientrada', this.modelVeiculosEntrada.form)

          if (!this.modelCalcularPreco.form.valor_fipe) {
            this.modelCalcularPreco.form.valor_fipe = 25000
            send.valor_fipe = 25000
          }

          this.modelCalcularPreco
            .getListagem({ params: send })
            .then(res => {
              const r = res && res.dados ? res.dados : null

              if (r) {
                this.modelVeiculosEntrada.form.valor_maximo = parseInt(r.valor_maximo_final)
                this.modelVeiculosEntrada.form.valor_minimo = parseInt(r.valor_minimo_final)
                this.modelVeiculosEntrada.form.valor_mercado = parseInt(r.valor_trade_in)
                this.modelVeiculosEntrada.form.valor_varejo = parseInt(r.valor_price_guru)
                this.resultadosCalcularPreco = r
              }

              aviso()
              this.notificacao('salvo', 'Calculado com sucesso', 3000)
              this.calculandoPreco = false

              if (passosCredereDetalhes) this.submitCrederePasso4(true)
            })
            .catch(error => {
              aviso()
              this.calculandoPreco = false
              this.notificacao('erro', error.msg)
            })
        } else {
          this.notificacao('erro', 'Todos os campos obrigatórios')
        }
      })
    },

    submitCrederePasso1 () {
      if (this.possuiCarroEntrada) {
        if (this.modelCalcularPreco.form.placa_uf) this.modelVeiculosEntrada.form.uf_placa = this.modelCalcularPreco.form.placa_uf
        if (this.modelCalcularPreco.form.km) this.modelVeiculosEntrada.form.km = this.modelCalcularPreco.form.km.toString()

        const send = clone(this.modelVeiculosEntrada.form)
        send.usuario_id = this.usuarioSelecionado.id

        if (this.dados && this.dados.valor_fipe) {
          send.valor_fipe = this.dados.valor_fipe
        }

        if (!this.veiculoQuitado) {
          send.valor_saldo_devedor = this.veiculoSaldoDevedor
          this.modelVeiculosEntrada.form.valor_saldo_devedor = this.veiculoSaldoDevedor
        }

        this.salvandoPasso1 = true
        const aviso = this.notificacao('enviando')

        this.modelVeiculosEntrada.salvar(send).then(res => {
          this.modelCredereProposta.form.veiculos_entradas_id = res && res.dados && res.dados.id ? res.dados.id : null

          aviso()
          this.notificacao('salvo', 'Salvo com sucesso', 3000)
          this.salvandoPasso1 = 'ok'
          this.passo1 = false
          this.passo2 = true
        }).catch(error => {
          aviso()
          this.salvandoPasso1 = false
          this.notificacao('erro', error.msg)
        })
      } else {
        this.salvandoPasso1 = 'ok'
        this.passo1 = false
        this.passo2 = true
      }
    },

    submitCrederePasso2 () {
      this.salvandoPasso2 = 'ok'
      this.passo2 = false
      this.passo3 = true
    },

    submitCrederePasso3 () {
      this.salvandoPasso3 = true
      const aviso = this.notificacao('enviando')

      const send = clone(this.modelCredereClient.form)

      if (
        send.customer.document_type === 'RG' &&
        send.customer.rg_issuing === ''
      ) {
        send.customer.rg_issuing = 'SPC'
      }
      send.customer.property = 1

      if (send.customer.job_reference.name === '') {
        send.customer.job_reference.name = 'Netcarros'
      }

      // campos valores padrao | default
      send.customer.nickname = null
      send.customer.nationality = 'Brasileira'
      send.customer.rg_date = this.$options.filters.removeAnosAgoraEng(5)
      send.customer.education_id = 6
      send.customer.marital_status_id = 1
      send.customer.address.build_type_id = 1
      send.customer.address.set_time_year = 2
      send.customer.address.set_time_month = 2
      send.customer.addresses[0].build_type_id = 1
      send.customer.addresses[0].set_time_year = 2
      send.customer.addresses[0].set_time_month = 1
      send.customer.job_reference.phone = {}
      send.customer.job_reference.phone.code = send.customer.phones[0].code
      send.customer.job_reference.phone.number = send.customer.phones[0].number
      // 3 - comercial
      // 1 - celular
      send.customer.job_reference.phone.phone_type_id = 3
      send.customer.job_reference.first_job = true
      send.customer.personal_references[0].name = send.customer.mother
      send.customer.personal_references[0].relationship = 'Mãe'
      send.customer.personal_references[0].city = send.customer.address.city
      send.customer.personal_references[0].phone.code = send.customer.phones[0].code
      send.customer.personal_references[0].phone.number = send.customer.phones[0].number
      send.customer.personal_references[0].phone.phone_type_id = send.customer.phones[0].phone_type_id
      send.customer.has_made_funding = false
      send.customer.have_credit_card = false
      send.customer.address.address_type_id = 1
      send.customer.bank_references[0].open_at = this.$options.filters.removeAnosAgoraEng(5)
      send.customer.bank_references[0].overdraft = false
      send.customer.phones[1].code = send.customer.phones[0].code
      send.customer.phones[1].number = parseInt(send.customer.phones[0].number) + 1
      send.customer.job_reference.previous_job_phone.phone_type_id = 2
      send.customer.have_bank_account = false

      if (!send.customer.job_reference.department) {
        send.customer.job_reference.department = 'Admin'
      }
      send.customer.job_reference.income_cents = send.customer.job_reference.income

      if (!(send.customer.job_reference.income_cents > 0)) {
        send.customer.job_reference.income_cents = 2000
      }
      if (send.customer.document_type === '1015') {
        send.customer.cnh = send.customer.rg
        send.customer.has_cnh = true
      }

      if (send.customer.has_cnh) {
        send.customer.cnh_type_id = 2
      }

      send.customer.job_reference.address.zip_code = send.customer.address.zip_code
      send.customer.job_reference.address.street = send.customer.address.street
      send.customer.job_reference.address.number = send.customer.address.number
      send.customer.job_reference.address.complement = send.customer.address.complement
      send.customer.job_reference.address.neighborhood = send.customer.address.neighborhood
      send.customer.job_reference.address.city = send.customer.address.city
      send.customer.job_reference.address.state_id = send.customer.address.state_id
      /* */

      if (send.customer.job_reference.income_cents) {
        send.customer.job_reference.income_cents = send.customer.job_reference.income_cents
          .toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
          .replace(/\D/g, '')
      }

      if (!send.customer.job_reference.professional_ocupation_id) {
        send.customer.job_reference.professional_ocupation_id = 1
      }

      if (!send.customer.job_reference.profession_id) {
        // Administrador
        send.customer.job_reference.profession_id = 2
      }

      if (!send.customer.bank_references.account_number) {
        send.customer.bank_references.account_number = ''
      }

      if (!send.customer.addresses[0].city) {
        delete send.customer.addresses[0]
      }

      // 3 - empresario
      // 7 - agropecuarista
      if (['3', '7'].indexOf(send.customer.job_reference.professional_ocupation_id.toString()) >= 0) {
        send.customer.accountant = {}
        send.customer.accountant.name = send.customer.name
        send.customer.accountant.relationship = 'Contador'
        send.customer.accountant.city = send.customer.name
        send.customer.accountant.phone = {}
        send.customer.accountant.phone.code = send.customer.phones[0].code
        send.customer.accountant.phone.number = send.customer.phones[0].number
        // 3 - comercial
        // 1 - celular
        send.customer.accountant.phone.phone_type_id = 3
      }

      send.customer.job_reference.another_income_type_id = 4
      send.customer.bank_references[0]._destroy = true
      send.customer.have_bank_account = false

      send.id = null

      this.modelCredereClient.salvar(send).then(res => {
        this.modelCredereClient.form.id = res && res.dados && res.dados.customer && res.dados.customer.id ? res.dados.customer.id : null
        if (this.modelCredereClient.form && this.modelCredereClient.form.id > 0) {
          let dados = LocalStorage.getItem('logado')
          if (dados && !dados.documento_lead) dados.documento_lead = {}
          dados.documento_lead.credere_client_id = this.modelCredereClient.form.id
          LocalStorage.set('logado', dados)

          if (this.usuarioSelecionado && !this.usuarioSelecionado.documento_lead) this.usuarioSelecionado.documento_lead = {}
          this.usuarioSelecionado.documento_lead.credere_client_id = this.modelCredereClient.form.id
        }
        aviso()
        this.submitSimulacaoCredere('3')
      }).catch(error => {
        aviso()
        this.salvandoPasso3 = false
        this.notificacao('erro', error.msg)
      })
    },

    updatePropostaCredere () {
      const send = { id: this.modelCredereProposta.form.id }
      send.response_client_credere = JSON.stringify(this.modelCredereClient.form)

      this.modelCredereProposta.salvar(send).then(() => {
      }).catch(error => {
        this.notificacao('erro', error.msg)
      })
    },

    async submitCrederePasso4 (preSalvamento = false) {
      if (!preSalvamento) {
        await this.buscarEntradasVeiculos()
      }

      const send = clone(this.modelCredereProposta.form)

      send.usuario_id = this.usuarioSelecionado.id
      if (!preSalvamento) send.response_client_credere = JSON.stringify(this.modelCredereClient.form)
      send.valor_extras = this.formPasso2.valorExtra

      send.situacao_credere_proposta_id = preSalvamento ? 1 : 2
      send.tipo_negociacao_id = this.pcd ? 3 : this.pj ? 1 : 2

      if (this.formPasso4) {
        send.simulacao_id = this.formPasso4.simulacao_id
        send.condition_id = this.formPasso4.condition_id
      } else {
        if (!preSalvamento) this.notificacao('aviso', 'Selecione uma parcela!')
      }

      this.salvandoPasso4 = true
      let aviso = null
      if (!preSalvamento) aviso = this.notificacao('enviando')

      send.DEAL = {}
      send.DEAL.UF_CRM_1602179548 = this.possuiCarroEntrada ? 'SIM' : 'NÃO'
      send.DEAL.UF_CRM_1606076503 = this.possuiCarroEntrada ? 831 : 833

      if (this.formPasso2.valorExtra > 0) {
        send.DEAL.UF_CRM_1600859558 = 123
        send.DEAL.UF_CRM_1600859585 = this.formPasso2.valorExtra.toString()
        send.DEAL.UF_CRM_1600859712 = this.formPasso2.valorExtra.toString()
      } else {
        send.DEAL.UF_CRM_1600859558 = 125
      }

      if (this.possuiCarroEntrada && !preSalvamento) {
        const marcaUsado = this.listas['marca_id'][this.listas['marca_id'].findIndex(ind => this.modelVeiculosEntrada.form.marca_id === ind.id)]
        const modeloUsado = this.listas['modelo_id'][this.listas['modelo_id'].findIndex(ind => this.modelVeiculosEntrada.form.modelo_id === ind.id)]
        const versaoUsado = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)]
        // valor de mercado carro
        send.DEAL.UF_CRM_1600859637 = this.possuiCarroEntrada ? parseInt(this.resultadosCalcularPreco && this.resultadosCalcularPreco.valor_varejo ? this.resultadosCalcularPreco.valor_varejo : 0) : 0
        if (send.DEAL.UF_CRM_1600859637 === 0) {
          send.DEAL.UF_CRM_1600859637 = parseInt(this.modelVeiculosEntrada.form.valor_varejo)
        }
        send.DEAL.UF_CRM_1600913053 = this.possuiCarroEntrada ? this.modelVeiculosEntrada.form.placa : ''
        send.DEAL.UF_CRM_603A9A8462094 = this.possuiCarroEntrada ? this.modelVeiculosEntrada.form.placa : ''
        send.DEAL.UF_CRM_603A9A83C0BD5 = (this.modelVeiculosEntrada.form.km).toString()
        send.DEAL.UF_CRM_1600913067 = this.possuiCarroEntrada && marcaUsado ? (marcaUsado.nome + ' Cod.:' + this.modelVeiculosEntrada.form.marca_id).toString() : ''
        send.DEAL.UF_CRM_1600913084 = this.possuiCarroEntrada && modeloUsado && versaoUsado ? (modeloUsado.nome + ' Versão: ' + versaoUsado.nome).toString() : ''
        send.DEAL.UF_CRM_1600913084 += this.possuiCarroEntrada ? (' Cod.:' + this.modelVeiculosEntrada.form.modelo_id + '-' + this.modelVeiculosEntrada.form.versao_id).toString() : ''
        // send.DEAL.UF_CRM_1600913106 = this.possuiCarroEntrada ? (this.modelVeiculosEntrada.form.ano_fabricacao + '/' + this.modelVeiculosEntrada.form.ano_modelo).toString() : ''
        send.DEAL.UF_CRM_1600913106 = this.possuiCarroEntrada ? (this.modelVeiculosEntrada.form.ano_fabricacao).toString() : ''
        // valor pedido carro na troca
        send.DEAL.UF_CRM_1600913147 = this.possuiCarroEntrada ? parseInt(this.modelVeiculosEntrada.form.valor_mercado) : 0
        // valor do veiculo na troca
        send.DEAL.UF_CRM_1600912818 = this.possuiCarroEntrada ? parseInt(this.modelVeiculosEntrada.form.valor_mercado) : 0
        send.DEAL.UF_CRM_1600912818 = send.DEAL.UF_CRM_1600912818.toString()

        // DESCRICAO
        send.DEAL.UF_CRM_1600859649 = 'Ano/Mod: ' + (this.modelVeiculosEntrada.form.ano_fabricacao + '/' + this.modelVeiculosEntrada.form.ano_modelo).toString()
        if (this.resultadosCalcularPreco && this.resultadosCalcularPreco.valor_varejo) {
          send.DEAL.UF_CRM_1600859649 += '<br />. Valor no varejo: ' + this.resultadosCalcularPreco.valor_varejo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
        send.DEAL.UF_CRM_1600859649 += '\n. Valor carro na troca: ' + this.modelVeiculosEntrada.form.valor_mercado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        send.DEAL.UF_CRM_1600912795 = send.DEAL.UF_CRM_1600859649
      }

      if (send.condition_id <= 60 || preSalvamento) {
        if (this.veiculo && this.veiculo.codigo_molicar && !preSalvamento &&
          (parseFloat(Math.abs(this.valorEntradaComp - this.valorVeiculoComp)) > parseFloat(this.valorMinimoFinanciamento))
        ) {
          this.modelCredereProposta.recurso = 'credere/modelos-veiculos'
          var res = await this.modelCredereProposta.salvar({ molicar_code: this.veiculo.codigo_molicar })
          send.vehicle_model_id = (res && res.dados && res.dados.vehicle_models && res.dados.vehicle_models.length > 0) ? res.dados.vehicle_models[0].id : null
        }
        console.log('antes do if', send.vehicle_model_id)
        if (!send.vehicle_model_id) { send.vehicle_model_id = 4455 }
        console.log('depois do if', send.vehicle_model_id)
        send.licensing_uf = 'SP'
        send.year_of_model = this.veiculo.ano_modelo
        send.year_of_manufacture = this.veiculo.ano_fabricacao
        send.zero_km = '1'
        send.commercial = false
        send.fuel_id = '5'

        if (send.situacao_credere_proposta_id === 1) {
          send.year_of_manufacture = 2020
          send.year_of_model = 2020
        }

        // send.condition_id = this.formPasso4.condition_id
        send.anuncio_id = this.$route.params.id
        send.parcela = this.formPasso4 && this.formPasso4.condition_id ? this.formPasso4.condition_id : null
        send.asset_value = this.valorVeiculoComp.toFixed(2)
        send.valor_entrada = this.valorEntradaComp.toFixed(2).toString()
        send.valor_extras = parseFloat(this.formPasso2.valorExtra).toFixed(2).toString()
        send.valor_financiado_credere = parseFloat((this.valorVeiculoComp - this.valorEntradaComp)).toFixed(2).toString()
        send.valor_parcela = parseFloat(this.formPasso4 && this.formPasso4.valorPrimeiraParcela ? this.formPasso4.valorPrimeiraParcela : 0).toFixed(2)
        send.valor_requerido = parseFloat(this.valorVeiculoComp - this.valorEntradaComp).toFixed(2)
        // send.valor_total = parseFloat(parseInt(this.formPasso4.condition_id) * this.formPasso4.valorPrimeiraParcela).toFixed(2) * 100
        send.valor_total = this.valorVeiculoComp.toFixed(2)
        send.banco_id = 341

        if (!(parseFloat(Math.abs(this.valorEntradaComp - this.valorVeiculoComp)) > parseFloat(this.valorMinimoFinanciamento))) {
          send.parcela = 1
          send.condition_id = 1
        }
        console.log('enviando proposta', send)
        this.modelCredereProposta.recurso = 'credere/propostas-sem-simulacao'
        this.modelCredereProposta
          .salvar(send)
          .then((res) => {
            if (res && res.dados && res.dados.id) {
              let dados = LocalStorage.getItem('logado')
              dados.observacao_aprovacao = send.response_client_credere
              LocalStorage.set('logado', dados)

              // { path: 'proposta/editar/:propostaid/anuncio/ref/:id?', name: 'painel-proposta-editar', component: () => import('pages/Painel/Proposta/Proposta.vue') },
              this.modelCredereProposta.form.id = res.dados.id
              this.$router.push({ name: 'painel-proposta-editar', params: { propostaid: res.dados.id, id: this.$route.params.id } })
              // this.modelCredereProposta.recurso = 'credere/propostas'
              // this.modelCredereProposta.getId({ id: res.dados.id }).then((res) => {
              //   if (res.dados) {
              //     this.responseBuscarStatusProposta(res.dados)
              //   }
              // })
            }
            if (!preSalvamento) {
              aviso()
              this.salvandoPasso4 = 'ok'
              if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
                LocalStorage.remove('usuarioSelecionado')
                this.modelCredereClient = new CredereClientModel()
                this.usuarioSelecionado = LocalStorage.getItem('logado')
              }
              this.toggleModal('financiamento')
            } else {
              this.salvandoPasso4 = false
            }
            LocalStorage.remove('passosCredereDetalhes')
          })
          .catch(error => {
            if (!preSalvamento) aviso()
            this.salvandoPasso4 = false
            this.notificacao('erro', error.msg)
          })
      } else {
        this.modelCredereProposta.recurso = 'credere/propostas'
        this.modelCredereProposta
          .salvar(send)
          .then((res) => {
            if (!preSalvamento) {
              aviso()
              this.salvandoPasso4 = 'ok'
              if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
                LocalStorage.remove('usuarioSelecionado')
                this.modelCredereClient = new CredereClientModel()
                this.usuarioSelecionado = LocalStorage.getItem('logado')
              }
              this.toggleModal('financiamento')
            }
            if (res && res.dados && res.dados.id) this.modelCredereProposta.form.id = res.dados.id
            LocalStorage.remove('passosCredereDetalhes')
          })
          .catch(error => {
            if (!preSalvamento) aviso()
            this.salvandoPasso4 = false
            this.notificacao('erro', error.msg)
          })
      }
    },

    async buscarEntradasVeiculos () {
      if (!(parseFloat(Math.abs(this.valorEntradaComp - this.valorVeiculoComp)) > parseFloat(this.valorMinimoFinanciamento))) {
        this.modelCredereProposta.form.veiculos_entradas_id = null
        this.modelVeiculosEntrada.form.id = null
      }
      if (this.modelCredereProposta.form.veiculos_entradas_id === null && this.possuiCarroEntrada) {
        const send1 = clone(this.modelVeiculosEntrada.form)
        send1.usuario_id = this.usuarioSelecionado.id

        if (this.dados && this.dados.valor_fipe) {
          send1.valor_fipe = this.dados.valor_fipe
        }

        if (this.modelCalcularPreco.form.placa_uf) send1.uf_placa = this.modelCalcularPreco.form.placa_uf
        if (this.modelCalcularPreco.form.km) send1.km = this.modelCalcularPreco.form.km.toString()

        return this.modelVeiculosEntrada.salvar(send1).then(res => {
          this.modelCredereProposta.form.veiculos_entradas_id = res && res.dados && res.dados.id ? res.dados.id : null
        }).catch(error => {
          console.log('error', error)
        })
      } else {

      }
    },

    // Methods Listagem
    buscarTodasAsListagens () {
      this.buscandoTodasAsListagens = true
      let promises = this.listagens.map(tipo => {
        if (tipo !== 'modelo_id' && tipo !== 'versao_id') {
          let model = new this.models[tipo]()
          return model.getListagem({ params: { limit: 1000 } })
        } else {
          return new Promise((resolve) => { resolve(true) })
        }
      })
      Promise.all(promises)
        .then(response => {
          this.listagens.forEach((tipo, index) => {
            this.listas[tipo] = response[index].dados ?? []
          })
          let listas = this.listas
          if (LocalStorage.has('veiculo-listas')) {
            listas = LocalStorage.getItem('veiculo-listas')
            listas = [...listas, ...this.listas]
          }
          LocalStorage.set('veiculo-listas', listas)
          this.buscandoTodasAsListagens = false
        })
        .catch(() => {
          this.buscandoTodasAsListagens = false
        })
    },
    removeDuplicates (myArr, prop, incluir = null) {
      return myArr.filter((obj, pos, arr) => {
        if (incluir !== null) {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos || incluir === obj['id']
        } else {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        }
      })
    },
    buscarListagem (tipo) {
      if (this.listagens.includes(tipo)) {
        switch (tipo) {
          case 'modelo_id':
            // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, { modelo_id: null, versao_id: null })
            this.buscarListagemRequest(tipo, false, `marca_id:${this.modelVeiculosEntrada.form.marca_id}`)
            break
          case 'versao_id':
            this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, { versao_id: null })
            this.buscarListagemRequest(tipo, false, `modelo_id:${this.modelVeiculosEntrada.form.modelo_id}`)
            break
          default:
            this.buscarListagemRequest(tipo)
            break
        }
      }
    },

    buscarListagemRequest (tipo, promise, search = '', relacao = false) {
      let model = new this.models[`${tipo}`](relacao)
      this.carregamentos[`${tipo}`] = true
      if (promise) {
        return model.getListagem({ params: { search, limit: 1000, orderBy: relacao ? '' : 'nome', sortedBy: 'asc' } })
      } else {
        model
          .getListagem({ params: { search, limit: 1000, orderBy: 'nome', sortedBy: 'asc' } })
          .then(response => {
            this.carregamentos[`${tipo}`] = false

            if (tipo === 'versao_id') {
              this.listaVersao = clone(response.dados)
              let data = []
              data[1] = this.removeDuplicates(response.dados, 'nome', this.modelVeiculosEntrada.form.versao_id)
              response.dados = data[1]
            }
            if (this.modelVeiculosEntrada.form[`${tipo}`] > 0 && response.dados.findIndex(ind => this.modelVeiculosEntrada.form[`${tipo}`] === ind.id) === -1) {
              this.modelVeiculosEntrada.form[`${tipo}`] = ''
            }

            this.listas[`${tipo}`] = response.dados ?? []
            if (LocalStorage.has('veiculo-listas')) {
              let listas = LocalStorage.getItem('veiculo-listas')
              listas[`${tipo}`] = response.dados
              LocalStorage.set(`veiculo-listas`, listas)
              this.keys[`${tipo}`]++
              if (tipo === 'versao_id') {
                this.validarAnoVeiculo()
              }
            }
            this.$refs.formCrederePasso1.resetValidation()
          })
          .catch(() => {
            this.carregamentos[`${tipo}`] = false
          })
      }
    },

    filtrarListagem (tipo) {
      const lista = clone(this.listas[`${tipo}`])
      const regex = new RegExp(this.filtrosListagens[`${tipo}`].termo, 'i')
      this.filtrosListagens[`${tipo}`].filtrado = lista.filter(l => l.nome.match(regex))
      this.listas[`${tipo}`] = this.filtrosListagens[`${tipo}`].filtrado
    },
    resetarListagem (tipo) {
      this.filtrosListagens[`${tipo}`].termo = ''
      this.buscarListagem(tipo)
    }
  },
  filters: {
    tipoNome (tipo) {
      if (tipo) {
        if (tipo === 'tipo_retomada_id') {
          return 'Origem de entrada'
        }
        let nome = tipo.replace('_id', '')
        if (nome) {
          return nome.charAt(0).toUpperCase() + nome.slice(1)
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}
